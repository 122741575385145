import {Connect} from "@ibm-aspera/connect-sdk-js";
import {TransferInfo} from "@ibm-aspera/connect-sdk-js/src/core/types";
import {
    ConnectError,
    ConnectSpec,
    StartTransferOutput,
    TransferSpec,
} from "@ibm-aspera/connect-sdk-js/dist/esm/core/types";
import { useRef } from "react";
import {Project, Resource, ResourceUploadState, UploadStatus} from "../api";
import {GetAsperaApi} from "../api/apiConfig";
import { useProjects } from "../Behaviors/projects";
import { getMappedResources } from "../Behaviors/projectTools";
import { useStore } from "../State/zustandStore";

const connectClient = new Connect();

connectClient.initSession("IngestionTool");

async function getTransfers() : Promise<TransferInfo[]> {
    return new Promise((resolve, reject) => {

        connectClient.getAllTransfers({
            success: (transfers) => resolve(transfers.transfers),
            error: () => resolve([]),
        });
    });
}

export const useAsperaApi = () => {
    const {updateUploadStates, startIngest, revalidateProject} = useProjects(undefined);
    const {projects, aspera} = useStore();
    const {setAllUploadProgress, clearUploadProgress} = aspera;
    const projectsRef = useRef(projects);

    projectsRef.current = projects;

    async function createTransferRequest(projectId: string, resources: Resource[]) {
        // When the transfer starts, set upload status to zero percent on all the files.
        // If the ui doesn't update these outside of the callback closure, then they
        // won't be updated inside of it
        async function transferStarted(output: StartTransferOutput) {
            for (const spec of output.transfer_specs) {
                const uploadStates: ResourceUploadState[] = [];

                for (const file of spec.transfer_spec.paths) {
                    uploadStates.push({ localPath: file.source, uploadStatus: UploadStatus.InProgress, uploadPercentage: 0 });
                };
                updateUploadStates(projectId, uploadStates);
            }
        }

        const transfers = await getTransfers();
        const projectTransfer = transfers.find(t => t.transfer_spec.cookie === projectId);

        if (projectTransfer) {
            if (["completed", "cancelled", "removed", "failed"].includes(projectTransfer.status)) { // Transfer is not active, okay to place new one
                projectsRef.current.logProjectEvent(projectId!, `Previous transfer ${projectTransfer.uuid} has status ${projectTransfer.status}`);
                await connectClient.removeTransfer(projectTransfer.uuid);
                clearUploadProgress(projectId);
            } else { // Transfer is active, don't start a new one
                projectsRef.current.logProjectEvent(projectId!, `Can't start transfer because of existing transfer ${projectTransfer.uuid}`);
                return Promise.resolve();
            }
        }

        const api = await GetAsperaApi();
        const fileResources: {[k: string]: string} = {};

        for (const r of resources) {
            fileResources[r.id!] = r.localPath!;
        };

        const response = await api.asperaUploadSpecPost(projectId!, fileResources);
        const transferSpec = response.data;
        return new Promise((resolve, reject) => {
            const t = transferSpec as TransferSpec;

            connectClient.startTransfer(t, transferSpec as ConnectSpec, {
                success: (transfer) => {
                    projectsRef.current.logProjectEvent(projectId!, `Created transfer ${transfer.transfer_specs[0].uuid} with ${transfer.transfer_specs[0].transfer_spec.paths.length} files`);
                    resolve(transferStarted(transfer));
                },
                error: async function (error: ConnectError) {
                    resolve("failed");
                },
            });
        });
    }

    async function handleTransferEvents(type: any, data: { transfers: TransferInfo[]; }) {

        for (const transfer of data.transfers) {
            const projectId = transfer.transfer_spec.cookie;

            if (!projectId) {
                console.error(`Aspera transfer with no project id ${transfer.uuid}`);
                return;
            }

            try {
                switch (transfer.status) {
                case "running":
                    // Only update file status if it is for the currently selected project
                    if (projectsRef.current.selectedProjectId === projectId) {
                        const statuses = new Map<string, number>();

                        for (const file of transfer.files?.filter(f => f.bytes_written > 0)) {
                            statuses.set(file.file, file.bytes_written / file.bytes_expected);
                        }
                        setAllUploadProgress(projectId, statuses);
                    }

                    break;
                case "completed":
                    const uploadStates: ResourceUploadState[] = [];

                    for (const file of transfer.files?.filter(f => f.bytes_written > 0)) {
                        const filePath = transfer.transfer_spec.paths.find(p => p.source === file.file);

                        if (filePath) {
                            const uploadPercentage = file.bytes_written / file.bytes_expected;

                            // @ts-expect-error
                            uploadStates.push({ localPath: file.file, uploadStatus: (uploadPercentage === 1 ? UploadStatus.Complete : UploadStatus.Unknown), uploadPercentage: uploadPercentage, s3Path: transfer.transfer_spec.s3_path });
                        }
                    }

                    const updatedProject = await updateUploadStates(projectId, uploadStates);

                    await connectClient.removeTransfer(transfer.uuid);
                    clearUploadProgress(projectId);
                    projectsRef.current.logProjectEvent(projectId!, `Completed transfer ${transfer.uuid}`);

                    // the api determines when an ingestion can start based on the upload status of
                    // the effective resources.  If the startIngest flag is set, start the ingestion.
                    if (updatedProject.startIngestion) {
                        const selectedCprsShipments = projectsRef.current.selectedCprsShipments.get(updatedProject.projectId!);

                        await startIngest(updatedProject, "start ingest flag", selectedCprsShipments);

                    } else { // Otherwise validate
                        await revalidateProject("uploads completed", updatedProject);
                    }

                    break;
                case "failed":
                    const failedUploadStates: ResourceUploadState[] = [];

                    for (const file of transfer.files?.filter(f => f.bytes_written > 0)) {
                        if (file) {
                            const uploadPercentage = file.bytes_written / file.bytes_expected;

                            failedUploadStates.push({ localPath: file.file, uploadStatus: (uploadPercentage === 1 ? UploadStatus.Complete : UploadStatus.Unknown), uploadPercentage: uploadPercentage });
                        }
                    }

                    const failedProject = await updateUploadStates(projectId, failedUploadStates);

                    await connectClient.removeTransfer(transfer.uuid);
                    clearUploadProgress(projectId);
                    projectsRef.current.logProjectEvent(projectId!, `Failed transfer ${transfer.uuid}`);
                    await revalidateProject("uploads completed", failedProject);
                    break;
                // cancelled
                // initiating
                // queued
                // removed
                // willretry
                default:
                    break;
                }
            } catch (error) {
                //@ts-expect-error
                projectsRef.current.logProjectEvent(projectId, `Aspera error ${transfer.uuid} status ${transfer.status} failed with the error:${error?.message}`);
            }
        }
    }

    function GetTransferEventForwarder() : (type: any, data: { transfers: TransferInfo[] }) => void {
        return handleTransferEvents;
    }

    function watchForAsperaEvents() {
        const forwarder = GetTransferEventForwarder();

        connectClient.addEventListener(Connect.EVENT.TRANSFER, forwarder);
    }

    function stopWatchingForAsperaEvents() {
        connectClient?.removeEventListener(Connect.EVENT.TRANSFER);
    }

    // TODO: make this do something.  Previous dispatch was not handled
    async function cancelUpload(resource: Resource) {
        /*
        if (resource.asperaTransferId) {
            await connectClient.removeTransfer(resource.asperaTransferId)
                .then(
                    () =>
                        dispatch({
                            name: "aspera.canceled",
                            projectId: state.selectedProjectId!,
                            resources: [resource],
                        }),
                )
                .catch(
                    () =>
                        dispatch({
                            name: "aspera.canceled",
                            projectId: state.selectedProjectId!,
                            resources: [resource],
                        }),
                );
        }
        */
    }

    async function refreshResourceUploadStatus(resources: Resource[]) {
        // resources.forEach(async resource => {
        //     const transfer = await getTransferForResource(resource);

        //     if (transfer) {
        //         await handleTransferEvents(null, { transfers: [transfer]});
        //     }
        // });
    }

    async function uploadResources(resources: Resource[], projectId: string) {
        await createTransferRequest(projectId, resources);
    }

    async function uploadMapped(project: Project) {
        const resourcesToUpload = getMappedResources(project);

        if (resourcesToUpload.length > 0) {
            await uploadResources(resourcesToUpload, project.projectId!);
        }
    }

    async function uploadAll(project: Project) {
        const resourcesToUpload = project.resources!;

        if (resourcesToUpload.length > 0) {
            await uploadResources(resourcesToUpload, project.projectId!);
        }
    }
    return {
        watchForAsperaEvents,
        cancelUpload,
        uploadAll,
        stopWatchingForAsperaEvents,
        refreshResourceUploadStatus,
        uploadResources,
        uploadMapped,
    };
};
