import {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {ProductInfo} from "../../../ProductInfo/ProductInfo";
import {Messages} from "../../Messages";
import { useStore } from "../../../State/zustandStore";
import {ActionBar} from "../../ActionBar";
import {RefreshButton} from "../../../Buttons/Buttons";
import {useProjects} from "../../../Behaviors/projects";
import { ResourceMode, getResourceListMode } from "../../../Behaviors/projectTools";
import {DraftBusy} from "./DraftBusy";
import {DigitalAudioTrackMap} from "./UPC_Tracks/DigitalAudioTrackMap";
import {DdpTrackMap} from "./DDP/DdpTrackMap";

export type AutoUpload = {
    identifier: string, // Either filename or resource id
    uploadStarted: boolean,
}

export const DraftView = () => {
    const state = useStore();
    const projectId = state.projects.selectedProjectId;
    const userPreferences = state.preferences;
    const { project } = useProjects(projectId!);
    const mode = getResourceListMode(project?.resources);
    var [detectedUpc, setDetectedUpc] = useState("");

    if (mode === ResourceMode.Unknown) {
        // Bug fix: when creating a "New Draft" clear any previously detectedUpc.
        detectedUpc = "";
    }
    return (
        <>
            <Container fluid={userPreferences.useFullWidth} className={"wrapper"} id={"mainContain"}>
                <DraftBusy />
                <div className={"modal-blocked  "}>
                    <Row className="mt-3">
                        <Col>
                            <h3> Product Info</h3>
                        </Col>
                    </Row>
                    <Row className=" bg-light mt-2 mb-3 pt-1 " style={{minHeight: "140px"}}>
                        <Col>
                            <ProductInfo detectedUpc={detectedUpc} />
                        </Col>
                    </Row>
                    <Row className={"mt-3 mb-3"}>
                        {mode & ResourceMode.File
                            ? <DdpTrackMap />
                            : <DigitalAudioTrackMap onUpcDetected={setDetectedUpc} />
                        }
                    </Row>
                </div>
                <Row className="mt-6  wrapper ">
                    <ActionBar mode={mode} />
                </Row>
                <Row className={"mt-3 mb-3 bg-white"}>
                    <Col lg={12} className="  table-container">
                        <Messages />
                        <div className={"float-end"}>
                            <RefreshButton />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
