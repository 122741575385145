import { create } from "zustand";

export enum IngestionJobState {
    Unknown,
    Requested,
    Starting,
    InProgress,
    Completed,
    Failed,
}

export type Preferences = {
    useFullWidth:boolean;
    hideBrowserWarning:boolean;
    enableLog:boolean;
    logExpanded:boolean;
}

type State = {
    projects: {
        selectedProjectId: string | undefined;
        projectMessages: Map<string, string>; // UI messages
        projectLog: Map<string, string[]>;
        selectedCprsShipments: Map<string, string[]>;
        busy: Set<string>;
    },
    preferences: Preferences,
    aspera: {
        uploadProgress: Map<string, Map<string, number>>;
    }
}

type Action = {
    projects: {
        setSelectedProjectId: (projectId: string) => void;
        clearSelectedProjectId: () => void;
        setProjectMessage: (projectId: string, message: string) => void;
        clearProjectMessage: (projectId: string) => void;
        logProjectEvent: (projectId: string, message: string) => void;
        clearProjectLog: (projectId: string) => void;
        setSelectedCprsShipments: (projectId: string, selections: string[]) => void;
        clearSelectedCprsShipments: (projectId: string) => void;
        setProjectBusy: (projectId: string, isBusy: boolean) => void;
    },
    preferences: {
        setPreference: (name: keyof(Preferences), setting: boolean) => void;
    },
    aspera: {
        setAllUploadProgress: (projectId: string, status: Map<string, number>) => void;
        clearUploadProgress: (projectId: string, resourceId?: string | undefined) => void;
    }
}

const userStateString = window.localStorage.getItem("USER_PREFERENCES");

const defaultUserPreferences = {
    useFullWidth: false,
    hideBrowserWarning: false,
    enableLog: false,
    logExpanded: true,
} as Preferences ;

const storedUserPreferences = userStateString ?
    JSON.parse(userStateString)
    : null;
const userPreferences = {...defaultUserPreferences, ...storedUserPreferences};

export const useStore = create<State & Action>(set => ({
    projects: {
        selectedProjectId: undefined,
        setSelectedProjectId: projectId => set(old => {
            if (process.env.NODE_ENV === "development") {
                // eslint-disable-next-line no-console
                console.log(`${projectId}: selected`);
            }
            return { projects: {...old.projects, selectedProjectId: projectId } };
        }),
        clearSelectedProjectId: () => set(old => {
            if (process.env.NODE_ENV === "development") {
                // eslint-disable-next-line no-console
                console.log(`${old.projects.selectedProjectId}: clear`);
            }
            return { projects: {...old.projects, selectedProjectId: undefined }};
        }),
        projectMessages: new Map(),
        setProjectMessage: (projectId, message) => set(old => ({
            projects: {...old.projects, projectMessages: new Map(old.projects.projectMessages).set(projectId, message) },
        })),
        clearProjectMessage: projectId => set(old => {
            const newMap = new Map(old.projects.projectMessages);

            newMap.delete(projectId);
            return { projects: {...old.projects, projectMessages: newMap }};
        }),
        projectLog: new Map(),
        logProjectEvent: (projectId, message) => set(old => {
            if (process.env.NODE_ENV === "development") {
                // eslint-disable-next-line no-console
                console.log(`${projectId}: ${message}`);
            }

            if (old.preferences.enableLog) {
                const newMap = new Map(old.projects.projectLog);
                const formattedMessage = `${new Date().toLocaleString()}: ${message}`;

                newMap.has(projectId)
                    ? newMap.get(projectId)?.push(formattedMessage)
                    : newMap.set(projectId, [formattedMessage]);
                return { projects: { ...old.projects, projectLog: newMap }};
            } else {
                return old;
            }
        }),
        clearProjectLog: (projectId) => set(old => {
            const newMap = new Map(old.projects.projectLog);

            newMap.delete(projectId);
            return { projects: {...old.projects, projectLog: newMap }};
        }),
        selectedCprsShipments: new Map(),
        setSelectedCprsShipments: (projectId, selections) => set(old => {
            // Contrary to available information and widespread wistom,
            // it seems it is necessary to mutate the exising map for
            // selected shipments.  If doing the normal method of creating
            // a new map then the changes are not visible until the next render.
            const newMap = old.projects.selectedCprsShipments;

            newMap.set(projectId, selections);
            return { projects: {...old.projects, selectedCprsShipments: newMap}} ;
        }),
        clearSelectedCprsShipments: projectId => set(old => {
            const newMap = new Map(old.projects.selectedCprsShipments).set(projectId, []);
            return { projects: {...old.projects, selectedCprsShipments: newMap } };
        }),
        busy: new Set(),
        setProjectBusy: (projectId, isBusy) => set (old => {
            const newBusy = new Set(old.projects.busy);

            if (isBusy) {
                newBusy.add(projectId);
            } else {
                newBusy.delete(projectId);
            }
            return { projects: {...old.projects, busy: newBusy }};
        }),
    },
    preferences: {
        useFullWidth: userPreferences.useFullWidth,
        hideBrowserWarning: userPreferences.hideBrowserWarning,
        enableLog: userPreferences.enableLog,
        logExpanded: userPreferences.lobExpanded,
        setPreference: (name: keyof(Preferences), setting) => set(old => {
            const newPreferences = {...old.preferences} as Preferences;

            newPreferences[name] = setting;
            window.localStorage.setItem("USER_PREFERENCES", JSON.stringify(newPreferences));
            return { preferences: {...old.preferences, ...newPreferences }};
        }),
    },
    aspera: {
        uploadProgress: new Map(),
        setAllUploadProgress: (projectId, status) => set(old => {
            const newUploadProgress = old.aspera.uploadProgress; // Copy the projects map

            newUploadProgress.delete(projectId); // remove the old project if it exists
            newUploadProgress.set(projectId, status); // set the resource upload percent
            return { aspera: {...old.aspera, uploadProgress: newUploadProgress }}; // set the new projects map
        }),
        clearUploadProgress: (projectId, resourceId) => set(old => {
            const newUploadProgress = new Map(old.aspera.uploadProgress);

            if (resourceId) {
                newUploadProgress.get(projectId)?.delete(resourceId); // Clear for single resource
            } else {
                newUploadProgress.delete(projectId); // Clear entire project
            }
            return { aspera: {...old.aspera, uploadProgress: newUploadProgress }};
        }),
    },
}));
