import { FileSystemFileHandle } from "file-system-access";
import WavParser from "./WavParser/wavParser";
import AudioFileFormat from "./WavParser/audioFileFormat";

export const ParseWavFile = async (handle: FileSystemFileHandle) => {
    const reader = new FileReader();
    const file = await handle.getFile();
    const BufferSize = 2048;
    const parser = new WavParser(file.name, file.size);

    async function seekAndRead(position: number) {
        return new Promise((resolve, reject) => {
            if (position < 0 || position >= file.size) {
                alert("Invalid position");
                return;
            }

            const reader = new FileReader();
            const blob = file.slice(position, position + BufferSize + 64); // Read 1000 bytes from the specified position

            reader.onload = function (event) {
                const chunkContent = event.target?.result;

                resolve(chunkContent); // Resolve the promise with the file content
            };
            reader.onerror = function () {
                reject(new Error("Error reading the file")); // Reject the promise if there's an error
            };
            reader.readAsArrayBuffer(blob); // Use readAsArrayBuffer or readAsDataURL if needed
        });
    }

    async function parseHeader(chunk: Blob) {
        return new Promise<AudioFileFormat | null>((resolve, reject) => {
            reader.onload = async (event: ProgressEvent<FileReader>) => {
                if (event?.target?.error) {
                    reject(event.target.error);
                }

                if (event?.target?.result) {
                    const arrayBuffer = event.target.result as ArrayBuffer;

                    if (await parser.parseWav(arrayBuffer, seekAndRead)) {
                        resolve(parser.getPcmConfig());
                    } else {
                        reject(parser.getErrors());
                    }
                }
            };
            reader.readAsArrayBuffer(chunk);
        });
    }

    const blob = file.slice(0, BufferSize + 64);
    var result = await parseHeader(blob);
    return result;
};
